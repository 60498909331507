<template>
    <section class="content">
    <div class="flex justify-content-center">
    <div class="w-100 card">
    <div class="card-body">
        <div class="row">
        <div class="col-xl-12 col-md-12 flex">
            <div class="row">
                <div class="col-auto">
                <h4>Per Tanggal</h4>
                </div>
                <div class="col-md-2 col-xs-4">
                <div class="input-group mb-3">
                    <input type="text" ref="daterange" class="form-control" style="border-right: 0"/>
                    <div class="input-group-append">
                    <div
                        class="input-group-text"
                        style="background-color: #fff">
                        <span><span class="fa fa-calendar"></span></span>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        </div>
        <div class="mb-2"></div>
            <table style="width:100%" class="table table-hover" ref="tblneraca">
            <thead>
                <tr>
                <th>NO. AKUN</th>
                <th>NAMA AKUN</th>
                <th>TOTAL</th>
                </tr>
            </thead>
            <tbody @click="handleClick"></tbody>
            </table>
    </div>
    </div>
    </div>
    </section>
</template>
<script>
import { authFetch, createTable, formatCurrency } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import datepicker from "@/components/Datepicker";
import Daterangepicker from "daterangepicker";
import moment from "moment";
import "daterangepicker/daterangepicker.css";

export default {
    name: "Labarugi",
    data() {
        return {
            dt1: "",
            dt2: "",
            dateStart: "",
            dateEnd: "",
            errors: [],
            method: "",
            jurnal: {
                coa_id: '',
                coa_no: '',
                coa_name: '',
            },
            roles: "",
            role_id: "",
            coa_id: "",
        };
    },
    components: {
        datepicker,
    },
    created: function () {
        this.roles = this.$route.meta.roles;
        console.log("load initial data", this.$route);
        this.dateStart = moment().add(-1, 'M').startOf("month").format("YYYY-MM-DD");
    },
    methods: {
        onPeriode() {
        this.tbl.api().ajax.reload();
        },
        handleClick(e) {
            const er = this.$refs;
            if (e.target.matches(".link-role")) {
            }
        },
    },
    mounted() {
    const e = this.$refs;
    const self = this;

    new Daterangepicker(this.$refs.daterange, {
        startDate: moment().startOf("month"),
        endDate: moment(),
        singleDatePicker: true,
        showDropdowns: true,
        locale: {
            format: 'DD/MM/YYYY'
        }
        }, function (d1, d2) {
        self.dateStart = d1.format("YYYY-MM-DD");
        self.dateEnd = d2.format("YYYY-MM-DD");
        self.onPeriode();
        });
    
    this.tbl = createTable(e.tblneraca, {
        title: "",
        ajax: "/report/neraca",
        roles: this.$route.params.roles,
        selectedRowClass: "",
        serverSide: true,
        frame: false,
        processing: true,
        sort: false,
        language: {
            processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'},
        lengthMenu: [[10, 25, 50, 100, 1000, -1], [ 10, 25, 50, 100, 1000, 'Semua' ]],
        displayLength: 1000,
        lengthChange: true,
        paramData: function (d) {
            d.dt1 = self.dateStart;
        },
        columns: [
            { data: "coa_no" },
            { data: "coa_name" },
            { 
            data: "total", 
            class:'text-right',
            render: function(data, type, row, meta) {
                return "Rp " + formatCurrency(data);
            }
            },
        ],
        rowGroup: {
            endRender: function ( rows, group ) {
                var avg = rows
				.data()
				.pluck('total')
				.reduce( function (a, b) {
                    var x = parseFloat( a ) || 0;
                    var y = parseFloat( b ) || 0;
                    return x + y;
                }, 0);
			// return 'Total '+group+': '+ ' Rp '+ formatCurrency(avg);
                return $('<tr/>')
                    .append( '<td colspan="2"> Total '+group+'</td>' )
                    .append( '<td class="text-right">Rp '+formatCurrency(avg)+'</td>' );
            },
            dataSrc: ['coa_category1', 'coa_category2']
        },
        filterBy: [0],
        rowCallback: function (row, data) {},
        initComplete: function () {
            $('.loading-overlay').removeClass('show');
        },
        });
    },
}
</script>